<script>
import { mapGetters } from 'vuex';
import utilsMixin from '@shared/mixins/utils';
import AssignmentDiscussion from '@shared/components/AssignmentDiscussion.vue';
import AssignmentValidationComment from '@shared/components/AssignmentValidationComment.vue';
import APITrainings from '@app/services/API/Trainings';

export default {
  mixins: [utilsMixin],
  components: {
    AssignmentDiscussion,
    AssignmentValidationComment,
  },
  data: () => ({
    currentTab: 'assignment',
    isFetching: false,
    isCommenting: false,
    isValidating: false,
    submission: null,
    trainingItem: null,
    newComment: '',
    newValidation: {
      decision: '',
      notation: '',
      teacher_validation_comment: '',
    },
  }),
  computed: {
    ...mapGetters('auth', {
      authUser: 'user',
    }),
    hasNotation() {
      return (
        this.sharedHasExpertPlan
        && this.trainingItem.specific_content.max_notation_enabled
        && this.trainingItem.specific_content.max_notation
      );
    },
    hasNewDiscussionEnabled() {
      return (
        this.sharedHasExpertPlan
        && this.trainingItem
          .specific_content
          .private_discussion_enabled
      );
    },
  },
  methods: {
    fetch() {
      this.isFetching = true;
      const { uuid, id, submissionId } = this.$route.params;

      Promise.all([
        APITrainings.getItemAssignmentSubmission(uuid, id, submissionId),
        this.$store.dispatch('trainings/findItem', {
          uuid,
          trainingItemId: id,
        }),
      ])
        .then(([{ data: submission }, trainingItem]) => {
          this.submission = submission;
          this.trainingItem = trainingItem;
        })
        .finally(() => (this.isFetching = false));
    },
    sendComment() {
      if (this.isCommenting) {
        return;
      }

      const { uuid, id, submissionId } = this.$route.params;
      this.isCommenting = true;
      APITrainings.addItemAssignmentSubmissionComment(
        uuid, id, submissionId,
        { comment: this.newComment },
      )
        .then(({ data }) => {
          this.newComment = '';
          this.submission = data;
        })
        .finally(() => (this.isCommenting = false));
    },
    validate() {
      if (this.isValidating) {
        return;
      }

      const { decision } = this.newValidation;

      if (decision === 'REJECTED') {
        if (!this.sharedHasExpertPlan) {
          this.$showMessage.goPro(() => {
            this.$router.push({
              name: 'plan',
              query: { onglet: 'vous' },
            });
          }, null, { plan: 'EXPERT' });

          return;
        }

        if (
          !this.trainingItem
            .specific_content
            .multi_submissions_enabled
        ) {
          this.$buefy.dialog.confirm({
            type: 'is-warning',
            title: 'Attention !',
            message: `
              Vous n'avez pas activé cette
              fonctionnalité.
            `,
            focusOn: 'cancel',
            confirmText: 'Je veux l\'activer',
            onConfirm: () => this.$router.push({
              name: 'training_uuid_assignment',
              params: {
                uuid: this.$route.params.uuid,
                id: this.$route.params.id,
              },
            }),
          });

          return;
        }
      } else if (
        this.hasNotation
        && this.newValidation.notation === ''
      ) {
        this.$buefy.dialog.alert(`
          Une note est obligatoire pour ce devoir.<br>
          Pour rappel, une fois la note et l'appréciation validées,
          elles ne seront plus modifiables.
        `);
        return;
      }

      this.$buefy.dialog.confirm({
        type: 'is-danger',
        title: 'Attention !',
        message: `
          Confirmez-vous l'action
          "${this.$t(`assignment.decision.${decision}`)}" ?
          ${decision === 'ACCEPTED' ? this.$t('assignment.decision.accepted_additional_message') : ''}
        `,
        focusOn: 'cancel',
        confirmText: 'Confirmer',
        onConfirm: () => {
          const { uuid, id, submissionId } = this.$route.params;
          this.isValidating = true;

          if (decision === 'REJECTED') {
            this.newValidation.notation = '';
          }

          this.$store.dispatch('trainings/validateItemAssignmentSubmission', {
            uuid,
            id,
            submissionId,
            validation: { ...this.newValidation },
          })
            .then((data) => {
              this.submission = data;
              this.newValidation.teacher_validation_comment = '';
            })
            .finally(() => (this.isValidating = false));
        },
      });
    },
  },
  created() {
    this.fetch(this.filterStatus);
  },
};
</script>

<template>
  <b-modal
    active
    scroll="keep"
    :canCancel="['escape', 'outside']"
    @close="$router.push({
      name: 'training',
      params: {uuid: $route.params.uuid}
    })"
  >
    <b-skeleton v-if="isFetching" height="400" />
    <AppCardModal v-else>
      <template #title>
        Devoir de
        {{ submission.customer.firstname }}
        {{ submission.customer.lastname }}
      </template>
      <template #body>
        <div class="level">
          <div class="level-left">
            <div class="tags">
              <span
                class="tag is-outlined"
                v-t="`assignment.status.${submission.status}`"
              />
              <span
                v-if="submission.created_at"
                class="tag is-outlined"
              >
                commencé le
                {{ submission.created_at | momentFromUTC | moment('DD/MM/YY [à] HH:mm') }}
              </span>
              <span
                v-if="submission.customer_submission_at"
                class="tag is-outlined"
              >
                soumis le
                {{ submission.customer_submission_at | momentFromUTC | moment('DD/MM/YY [à] HH:mm') }}
              </span>
              <span
                v-if="submission.teacher_validation_at"
                class="tag is-outlined"
              >
                corrigé le
                {{ submission.teacher_validation_at | momentFromUTC | moment('DD/MM/YY [à] HH:mm') }}
              </span>
            </div>
          </div>

          <div v-if="hasNotation" class="level-right">
            <b-tooltip
              v-if="submission.status == 'PENDING_TEACHER'"
              label="Mettre une note"
              position="is-bottom"
            >
              <b-button
                class="mr-2"
                type="is-text"
                tag="a"
                href="#correction"
                icon-left="pen"
              />
            </b-tooltip>

            <span class="tag is-black">
              <template v-if="submission.notation">
                Note : {{ submission.notation }} / {{ submission.max_notation }}
              </template>
              <template v-else-if="submission.status == 'COMPLETED'">
                Aucune note attribuée
              </template>
              <template v-else>
                Pas encore noté (/ {{ submission.max_notation }})
              </template>
            </span>
          </div>
        </div>

        <div class="tabs mb-10">
          <ul>
            <li
              :class="{'is-active': currentTab === 'assignment'}"
              @click.prevent="currentTab = 'assignment'"
            >
              <a href="#">Devoir</a>
            </li>
            <li
              :class="{'is-active': currentTab === 'discussion'}"
              @click.prevent="currentTab = 'discussion'"
            >
              <a href="#">Discussion privée</a>
            </li>
          </ul>
        </div>

        <div v-show="currentTab == 'assignment'">
          <section
            v-if="submission.teacher_validation_at"
            class="block"
          >
            <h2 class="title is-4 is-size-5-touch">
              L'appréciation
              <span class="has-text-weight-normal is-size-6">
                le {{ submission.teacher_validation_at | momentFromUTC | moment('DD/MM/YY [à] HH:mm') }}
                <template
                  v-if="authUser.firstname != submission.teacher.firstname"
                >
                  par {{ submission.teacher.firstname }}
                </template>
              </span>
            </h2>
            <AssignmentValidationComment
              :comment="submission.teacher_validation_comment"
            />
          </section>
          <section
            class="block">
            <h2 class="title is-4 is-size-5-touch">
              Les documents soumis
            </h2>
            <ul
              v-if="submission.paths"
              class="panel is-primary border-grey-2">
              <li
                v-for="(path, i) in submission.paths"
                :key="path.id"
                class="panel-block"
              >
                <p class="w-full flex-between-center py-2">
                  <a
                    class="has-text-clipped"
                    :href="path.path"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Soumission n° {{ i + 1 }}
                  </a>
                </p>
              </li>
            </ul>
            <p
              v-else
              class="box py-3">
              Aucun document.
            </p>
          </section>
          <section
            v-if="submission.status == 'PENDING_TEACHER'"
            id="correction"
            class="block"
          >
            <h2 class="title is-4 is-size-5-touch">
              Corriger le devoir
            </h2>
            <form @submit.prevent="validate">
              <b-field
                v-if="hasNotation"
                label="Note (obligatoire si vous validez la correction)"
                label-for="notation"
              >
                <b-input
                  type="number"
                  id="notation"
                  class="w-100"
                  :max="submission.max_notation"
                  v-model="newValidation.notation"
                />
                <p class="control">
                  <span class="button is-static has-text-black">
                    / {{ submission.max_notation }}
                  </span>
                </p>
              </b-field>
              <b-field
                label="Écrivez votre appréciation sur le devoir (obligatoire)"
                label-for="teacher_validation_comment"
              >
                <b-input
                  type="textarea"
                  rows="3"
                  id="teacher_validation_comment"
                  maxlength="5000"
                  required
                  v-model="newValidation.teacher_validation_comment"
                />
              </b-field>
              <p class="buttons is-right">
                <b-button
                  type="is-danger"
                  outlined
                  native-type="submit"
                  :loading="isValidating"
                  @click="newValidation.decision = 'REJECTED'"
                >
                  Demander une nouvelle soumission
                  <b-tooltip class="ml-3" position="is-top" multilined>
                    <template #content>
                      Vous pouvez permettre
                      à l'apprenant de resoumettre
                      son devoir afin de lui
                      donner une chance de s'améliorer.
                    </template>
                    <b-icon icon="info-circle" />
                  </b-tooltip>
                </b-button>
                <b-button
                  type="is-primary"
                  native-type="submit"
                  :loading="isValidating"
                  @click="newValidation.decision = 'ACCEPTED'"
                >
                  Valider la correction
                </b-button>
              </p>
            </form>
          </section>
          <section v-if="submission.history.length > 0" class="block">
            <h2 class="title is-4 is-size-5-touch">
              Historique
            </h2>
            <b-table class="box" :data="submission.history">
              <b-table-column
                label="Date"
                field="submission_at"
                v-slot="{ row }"
              >
                <b-tooltip>
                  <template #content>
                    {{ row.type === 'assignment_started' ? row.created_at :row.submission_at
                    | momentFromUTC
                    | moment('DD/MM/YY [à] HH:mm') }}
                  </template>
                  {{ row.type === 'assignment_started' ? row.created_at :row.submission_at
                  | momentFromUTC
                  | moment('from') }}
                </b-tooltip>
              </b-table-column>
              <b-table-column label="Évènement" field="type" v-slot="{ row }">
                <span v-t="`history_type.${row.type}`" />
              </b-table-column>
              <b-table-column
                label="Nombre de documents soumis"
                field="submission_path_count"
                numeric
                v-slot="{ row }"
              >
                {{ row.submission_path_count }}
              </b-table-column>
              <b-table-column
                label="Durée"
                field="type"
                numeric
                v-slot="{ row }"
              >
                <template v-if="row.type == 'customer_submission'">
                  {{ row.duration_since_assignment_creation | formatSeconds }}
                </template>
              </b-table-column>
            </b-table>
          </section>
        </div>

        <div v-show="currentTab == 'discussion'">
          <AssignmentDiscussion
            v-if="sharedHasExpertPlan"
            v-model="newComment"
            context="USER"
            :isSending="isCommenting"
            :submission="submission"
            :disabled="!hasNewDiscussionEnabled"
            @send="sendComment"
          />
          <div v-else class="block">
            <h2 class="title is-4 is-size-5-touch is-custom">
              Discussion privée
            </h2>
            <GoProWrapper planLock="EXPERT" :hasSep="false" />
          </div>
        </div>
      </template>
      <template #foot>
        <b-button
          type="is-text"
          @click="$router.push({
            name: 'training',
            params: {uuid: $route.params.uuid}
          })"
        >
          Fermer
        </b-button>
      </template>
    </AppCardModal>
  </b-modal>
</template>
